import { BasicUserInfo } from 'components/store/interfaces';
import { LogSeverity } from 'components/types/enums';
import { apiDomain } from './apiDomainHelper';
import { getLoginToken } from './auth';

const cloneDeep = require('clone-deep');

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const logoAltText = 'Land.com - Marketing Hub - BETA';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function traverse(obj: any, path: string): any {
  if (Object.keys(obj).length < 1) {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = path.split('.').reduce((a: any, c: string) => {
    return typeof a === 'undefined' ? undefined : a[c];
  }, obj);

  return typeof data != 'undefined' ? data : '';
}

export function updateObjectByPath<T>(obj: T, path: string, val: unknown, mutate = false): T {
  function deepUpdate<T, Key extends keyof T>(obj: T, path: string, val: T[Key]): T {
    if (!path) {
      return obj;
    }

    const pa = path.split('.');
    const cur = pa.shift() as Key;
    if (typeof cur !== 'undefined' && pa.length === 0) {
      obj[cur] = val;
    }

    if (!obj[cur]) {
      return obj;
    }

    obj[cur] = deepUpdate(obj[cur], pa.join('.'), val as unknown as T[Key][keyof T[Key]]);

    return obj;
  }

  // We do not want to mutate any object that is being held in State
  // We may need to write our own deep clone function or write our own if the JSON.parse/JSON.stringify does not meet our requirements
  return deepUpdate(!mutate ? cloneDeep(obj) : obj, path, val as T[keyof T]);
}

export interface TargetedLandApiError {
  target: string;
  messages: string[];
}

export interface LandApiResponse<T> {
  data: T;
  errors: TargetedLandApiError[];
  ok: boolean;
  status: number;
  statusText: string;
}

export async function postToLandApi<T>(url: string, data: unknown, label?: string): Promise<LandApiResponse<T>> {
  if (!label) {
    label = url.replace(/[\/]/g, '::');
  }
  const response = await fetchLandApiData<T>(label, url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  return response;
}

export async function fetchLandApiData<T>(
  name: string,
  relativePath: string,
  config: Record<string, unknown> = { method: 'GET' }
): Promise<LandApiResponse<T>> {
  return fetchApiData(name, apiDomain + relativePath, config);
}

export function displayPriceFormatter(price: number): string {
  return price !== 2 && price !== 1 ? '$' + price.toLocaleString() : price === 2 ? 'Auction' : 'Call for Price';
}

export async function fetchApiData<T>(
  name: string,
  url: string,
  config: Record<string, unknown> = { method: 'GET' }
): Promise<LandApiResponse<T>> {
  try {
    const rawResponse = await fetch(url, {
      // CORS calls to the api server all require credentials: 'include'
      credentials: 'include',
      ...config
    });

    try {
      const responseText = await rawResponse.text();
      const response = responseText ? JSON.parse(responseText) : {};

      return {
        ok: rawResponse.ok,
        status: rawResponse.status,
        statusText: rawResponse.statusText,
        ...response
      };
    } catch (err) {
      return {
        data: {} as T,
        errors: [{ messages: [err] } as TargetedLandApiError] as TargetedLandApiError[],
        ok: false,
        status: 422,
        statusText: err as string
      };
    }
  } catch (err) {
    return {
      data: {} as T,
      errors: [{ messages: [err] } as TargetedLandApiError] as TargetedLandApiError[],
      ok: false,
      status: 500,
      statusText: err as string
    };
  }
}

export function addMinutesToDate(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export function buildClassName(cmpName: string, options: string[] = [], selected = '', prev = ''): string {
  if (!cmpName) {
    return '';
  }

  const wrapper = `ccmp-${cmpName.replace(/\s/g, '-').toLowerCase()}`;
  let optionsStr = '';
  if (options.length > 0) {
    optionsStr = ` ${options.includes(selected) ? wrapper + '-' + selected : ''}`;
  }

  const className = prev ? `${prev + ' ' + optionsStr} ` : `${wrapper + optionsStr}`;
  return className;
}

export function downloadCsv(data: string, fileName: string): void {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export const createQaAttribute = (qaId: string): { [key: string]: boolean } | null => {
  if (!qaId) {
    return null;
  }

  return { ['data-qa-' + qaId.toLocaleLowerCase()]: true };
};

export function numberWithCommas(num: number): string {
  if (!num) {
    return '0';
  }
  return (num * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getPlural(num: number, unit: string): string {
  if (typeof num === 'undefined') {
    return '0';
  }
  return num > 1 ? `${unit}s` : unit;
}

export function isMobile(): boolean {
  return window.innerWidth < 600;
}

export const getPccUrl = async (user: BasicUserInfo, isLoggedIn: boolean): Promise<string> => {
  const pccDomain = process.env.REACT_APP_PCC_DOMAIN;
  if (!pccDomain) {
    console.error('Cannot open PCC--domain is not available');
    return '';
  }

  if (isLoggedIn && user?.isSeller && user?.canUsePCC) {
    const token = await getLoginToken();
    return `${pccDomain}/users/login.cfm?destURL=%2Fusers%2F%3Faction%3DEdit&logintoken=${token}&clrRct`;
  } else {
    return pccDomain;
  }
};

export function getUrlEnvironment(): string {
  const environment = process.env.REACT_APP_ENV;
  const targetEnvironment = environment === 'local' ? 'dev' : environment;
  return targetEnvironment === 'prod' ? '' : `-${targetEnvironment}`;
}

export function getLandUrl(): string {
  return `https://www${getUrlEnvironment()}.land.com`;
}

export function dateFormatter(dateString: string, style = 'short'): string {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const d = new Date(dateString);
  const year = d.getFullYear();
  const date = d.getDate();
  const monthName = months[d.getMonth()];

  if (style == 'short') {
    return `${d.getMonth() + 1}/${date}/${year}`;
  } else if (style == 'long') {
    return `${monthName} ${date}, ${year}`;
  }
  return `${d.getMonth() + 1}/${date}/${year}`;
}

export function verboseMonthYear(date: Date): string {
  const longMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  return `${longMonths[date.getMonth()]} ${date.getFullYear()}`;
}

export function currencyFormatter(val: number): string {
  return new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(val);
}

export function zeroToDash(val: string): string {
  return parseInt(val) == 0 ? '-' : numberWithCommas(parseInt(val));
}

// Flatten errors from Record<fieldName: string, messages: string[]> to string[] of
// format: [fieldname]: message.
export function flattenErrors(collection: Record<string, string[]>): string[] {
  const flattenedErrors = [] as string[];

  for (const key in collection) {
    const messages = collection[key];
    if (messages && messages.length > 0) {
      for (const message of messages) {
        // Capitalize first character of key so that we display, e.g., 'ExternalSite'
        // instead of 'externalSite'.
        //
        // todo: Add appropriate data to defaultFormStata<T> so that the fieldname used as
        // the key for the Record<string, string[]> error collections can be used to lookup
        // the human-friendly field label. This will allow us to display these errors here
        // using the field's label (e.g. External Site) instead of the internal field name
        // (e.g. externalSite).
        const fieldName = key.substr(0, 1).toUpperCase() + key.substr(1);
        flattenedErrors.push(`${fieldName}: ${message}`);
      }
    }
  }

  return flattenedErrors;
}

export function logToServer(message: string, referringUrl: string, logSeverity: LogSeverity): void {
  try {
    const body = JSON.stringify({
      message,
      referringUrl,
      logSeverity
    });

    console.warn('Logging to server:', body);

    fetch(`${apiDomain}/Logging/Log/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain'
      },
      body
    });
  } catch (ex) {
    console.error('Error trying to log to server:', ex);
  }
}

export function GetDisplayAddress(
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string
): string {
  let address = '';
  if (address1) {
    address += address1;
  }

  if (address2) {
    if (address.length > 0) {
      address += ' ';
    }

    address += address2;
  }

  if (city) {
    if (address.length > 0) {
      address += ', ';
    }

    address += city;
  }

  if (state) {
    if (address.length > 0) {
      address += ', ';
    }

    address += state;
  }

  if (zip) {
    if (address.length > 0) {
      address += ' ';
    }

    address += zip;
  }

  return address;
}

export const prependHttpsToURL = (url: string): string => {
  if (!url) {
    return '';
  }

  // If they start typing but without http we want to prepend it to the start.  (Or paste)
  if (url.length > 4 && !url.startsWith('http')) {
    return `https://${url}`;
  }

  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  }

  return url;
};

export function getPastDateByDays(days: number): Date {
  return new Date(new Date().setDate(new Date().getDate() - days));
}

export function formatDateYearMonthDay(date: Date): string {
  return date.toISOString().split('T')[0];
}

// Used to format a hasCurrency input as the user is changing it.
export function onChangePriceFormatter(value: number | string, hasCurrency = true): string {
  const numericPrice = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.-]+/g, '')) : (value as number);

  if (isNaN(numericPrice)) {
    return hasCurrency ? '$0' : '0';
  }

  const formattedPrice = numericPrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });

  return `${hasCurrency ? '$' : ''}${formattedPrice}`;
}

export function phoneFormatter(phone: string): string {
  // Replace all non-digit characters with empty string
  let digits = phone.replace(/\D/g, '');
  // Remove leading 1 if present
  if (digits[0] === '1') {
    digits = digits.slice(1);
  }

  if (digits.length !== 10) {
    // Invalid phone number
    return 'N/A';
  }

  // Format the first 10 digits into (###) ###-####
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
}

export const yesterday: Date = ((): Date => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
})();

export const twoYearsFromYesterday = new Date(yesterday);
twoYearsFromYesterday.setFullYear(yesterday.getFullYear() - 2);

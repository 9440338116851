
export const generateApiDomain = (host = window.location.hostname) : string => {
    const idx = host.indexOf('0');
		
    if (idx == -1) {
			return process.env.REACT_APP_LAND_API_DOMAIN || "";
    }
		const regex = /market(?<serverNumber>0[1-4])\.(?<env>[^\.]+)\.land\.com/;
		const match = regex.exec(host);
		const serverNumber = match?.groups?.serverNumber;
		const env = match?.groups?.env ;
		const apiDomain = `https://api${serverNumber}.${env}.land.com`;
		return apiDomain;
}

export const apiDomain = generateApiDomain();